export default {
  area: "宜阳县锦屏镇智慧社区",
  objectName: "",
  //url
  hostUrl: 'https://yyjp.zhsq.cloud',
  apiHttpsUrl: 'https://yyjpzapi.zhsq.cloud/',
  apiRequestUrl: 'https://yyjpzapi.zhsq.cloud/api',
  apiUploadUrl: 'https://yyjpzapi.zhsq.cloud/api/UploadFile/UploadImgs',
  apiUploadFiles: 'https://yyjpzapi.zhsq.cloud/api/UploadFile/UploadFiles',
  apiUploadvideo: 'https://yyjpzapi.zhsq.cloud/api/UploadFile/UploadQiniuVideo',
}